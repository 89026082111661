<template>
  <div class="flex flex-col gap-2.5">
    <ui-fields-input-search
      v-model="search"
      sync-v-model
      enabled-controlled
      placeholder="Find your interest"
    />
    <ui-loader
      :loading="pending"
      :error="error"
      static
    >
      <transition-fade mode="out-in">
        <transition-fade
          v-if="searchResults.length"
          group
          class="px-2.5 lg:px-7 pb-2.5 lg:pb-7 pt-0 flex flex-col gap-2.5"
        >
          <common-forms-parts-validate-interests-list-item
            v-for="(interestsGroup, index) in searchResults"
            :key="index"
            :interests-group="interestsGroup"
            :name="name"
          />
        </transition-fade>
        <ui-parts-interest-list-search-empty v-else />
      </transition-fade>
    </ui-loader>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    name?: string;
  };

  const props = withDefaults(defineProps<Props>(), {
    name: 'interest_ids'
  });
  const interestsStore = useInterestsStore();
  const { pending, error } = await useLazyAsyncData('public-interests', async () => {
    await interestsStore.fetchDefaultInterests();
    return true;
  });
  const { searchResults, search } = createInterestsList(
    computed(() => interestsStore.defaultInterests),
    undefined,
    useProp(props, 'name')
  );
</script>
