<template>
  <div
    v-if="prevHash || nextHash"
    class="flex flex-wrap sm:justify-end justify-center items-center gap-4"
  >
    <div class="col-auto">
      <ui-button
        icon-left="chevron_left"
        text="prev"
        size="md"
        :disabled="!prevHash"
        variant="secondary"
        @click="onSetPage(prevHash)"
      />
    </div>
    <div class="col-auto">
      <ui-button
        icon-right="chevron_right"
        text="next"
        size="md"
        :disabled="!nextHash"
        variant="secondary"
        @click="onSetPage(nextHash)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  type Props = {
    prevHash?: string;
    nextHash?: string;
    disableScroll?: boolean;
  };

  const props = defineProps<Props>();

  const emit = defineEmits(['set-page']);

  const onSetPage = (page?: string) => {
    if (!props.disableScroll) {
      window.scrollTo(0, 0);
    }
    emit('set-page', page);
  };
</script>
