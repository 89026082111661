<template>
  <div
    v-if="tabs && tabs.length"
    class="base-tabs"
  >
    <div
      ref="tabHeader"
      :class="headerClass"
    >
      <slot
        name="tab-headers"
        :tabs="tabs"
        :on-click-header="onClickHeader"
        :active-tab-index="activeTabIndex"
      >
        <ui-tabs-header-item
          v-for="(tab, i) in tabs"
          :key="tab.key || i"
          :text="tab.name"
          :variant="tab.variant"
          :icon="tab.icon"
          :active="i === activeTabIndex"
          :disable="tab.disable"
          @click="onClickHeader(tab.key || i)"
        >
          <slot
            name="tab-header-text"
            :index="i"
            :tab="tab"
          />
        </ui-tabs-header-item>
      </slot>
    </div>

    <div
      v-if="activeTabData && !hideContent"
      :key="currentStep"
      :class="wrapContentClasses"
    >
      <slot
        :active-tab-data="activeTabData"
        :on-prev="onPrev"
        :on-next="onNext"
        :has-prev="hasPrev"
        :has-next="hasNext"
        :active-tab-index="activeTabIndex"
      >
        <component
          :is="activeTabData.component"
          v-if="activeTabData.component"
          v-bind="activeTabData.bind"
        />
        <div v-else>
          {{ activeTabData.content }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
  type Props = {
    tabs: TabItem[];
    disabledScroll?: boolean;
    hideHeaderGroup?: boolean;
    disableClickHeader?: boolean;
    modelValue?: number | string;
    headerClasses?: ClassesType;
    wrapContentClasses?: ClassesType;
    hideContent?: boolean;
  };

  const props = withDefaults(defineProps<Props>(), {
    tabs: () => [],
    disabledScroll: false
  });
  const emit = defineEmits(['update:modelValue']);

  const tabHeader = ref<HTMLElement>();

  const currentStep = useVModel(props, 'modelValue', emit, {
    passive: true,
    defaultValue: 0
  });

  const headerClass = computed(() =>
    cn(
      'flex justify-center tabs-header mb-2.5 list-none m-0 bg-tab-header-bg rounded-xl md:w-max',
      {
        'tabs-header--group': !props.hideHeaderGroup
      },
      props.headerClasses
    )
  );

  const activeTabIndex = computed(() => {
    if (isNumber(currentStep.value) && props.tabs[currentStep.value]) {
      return currentStep.value;
    }
    const index = useFindIndex(props.tabs, ['key', currentStep.value]);
    return index > -1 ? index : 0;
  });

  const hasPrev = computed(() => activeTabIndex.value - 1 >= 0);
  const hasNext = computed(() => !!props.tabs[activeTabIndex.value + 1]);
  const activeTabData = computed<TabItem>(() => {
    return props.tabs[activeTabIndex.value];
  });

  const selectTab = (val: string | number) => {
    if (currentStep.value === val) return;
    currentStep.value = val;
  };

  const navStep = (isInc = true) => {
    const val = isInc ? activeTabIndex.value + 1 : activeTabIndex.value - 1;
    const nextItem = props.tabs[val];
    if (isNumber(currentStep.value) && nextItem) {
      selectTab(val);
      return;
    }
    if (nextItem?.key) {
      selectTab(nextItem.key);
    }
  };

  const onNext = () => navStep(true);
  const onPrev = () => navStep(false);

  const onClickHeader = (val: string | number) => {
    if (props.disableClickHeader) {
      return;
    }
    selectTab(val);
  };

  watch(currentStep, () => {
    if (!props.disabledScroll) {
      scrollToEl(tabHeader.value);
    }
  });
</script>

<style lang="scss" scoped>
  .tabs-header--group > .base-button {
    @apply rounded-none;
    &:first-child {
      @apply rounded-r-none rounded-l-sm;
    }
    &:last-child {
      @apply rounded-l-none rounded-r-sm;
    }
  }
</style>
