<template>
  <ui-loader
    :wrap-classes="loaderWrapClass"
    :static-slot="staticSlot"
    :static="static"
    :show-not-found="isEmpty"
    :loading="loading"
    :fixed="fixed"
    :error="error"
    :hide-overlay="hideOverlay"
    :overlay-classes="overlayClasses"
    :static-classes="staticClasses"
    :content-empty-class="contentEmptyClass"
    :content-empty-title="contentEmptyTitle"
    :content-empty-message="contentEmptyMessage"
    :content-empty-hide-image="contentEmptyHideImage"
    :opacity-overlay="opacityOverlay"
    :full-screen="fullScreen"
  >
    <slot>
      <div
        v-if="items"
        :class="wrapClass"
      >
        <slot
          v-for="(item, index) in items"
          name="item"
          :item="item"
          :index="index"
        />
      </div>
    </slot>
    <template #content-empty>
      <slot name="content-empty" />
    </template>
    <ui-pagination-lazy
      :prev-hash="prevHash"
      :next-hash="nextHash"
      class="mt-5"
      :disable-scroll="disableScroll"
      @set-page="$emit('set-page', $event)"
    />
  </ui-loader>
</template>

<script setup lang="ts">
  type Props = {
    navigation?: PaginationMetaCursor;
    items?: any[];
    showNotFound?: boolean;
    disableScroll?: boolean;
    wrapClass?: ClassesType;
    loaderWrapClass?: LoaderProps['wrapClasses'];
  } & Omit<LoaderProps, 'wrapClasses'>;

  const props = withDefaults(defineProps<Props>(), {
    wrapClass: 'flex flex-col md:gap-5 gap-3',
    staticSlot: true
  });

  defineEmits(['set-page']);

  const prevHash = computed(() => props.navigation?.prev_cursor);
  const nextHash = computed(() => props.navigation?.next_cursor);
  const isEmpty = computed(() => {
    if (!props.items) {
      return props.showNotFound;
    }
    return !props.items?.length;
  });
</script>
